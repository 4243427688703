import { createWithEqualityFn } from 'zustand/traditional';
import {shallow} from 'zustand/shallow';

 
// Create a store for handling the main part of the state.
// This store has states for the title and authorization status.
// It also has functions for setting those states.
const useMain = createWithEqualityFn(set => (
    {
        // A boolean to hold the authorization status.
        isAuthorized: false,
        // A function for setting the authorization status.
        setIsAuthorized:data => set({isAuthorized: data}),
    }
));

// A hook for accessing the main store.
// This hook uses a selector to return only the parts of the state that are needed.
// This way, components that use this hook won't re-render unless these specific parts of the state change.
const useMainStore = () => {
    const {isAuthorized, setIsAuthorized} =useMain(state => ({ 
        isAuthorized:state.isAuthorized,
        setIsAuthorized:state.setIsAuthorized,
    }), shallow);

    // Return these parts of the state and the functions that operate on them.
    return { isAuthorized,  setIsAuthorized};
};

// An object for handling authorization outside of React components.
// This object has a function for setting the authorization status.
export const apiAuth = {
    setIsAuthorized: isAuthorized => {
        // Set the authorization status in the main store.
        useMain.setState({ isAuthorized });
    }
};

// Export the hook so other components can use it.
export default useMainStore;
