//Gets the host environment
export const getBaseTspmCoreURL = () => {
  const { hostname, port, protocol } = window.location;

  //if port required add 4000 else hostname
  const hostName = port ? `${hostname}:4000` : `${hostname}`;

  const apiHostName = hostName.replace("itprocurement.jnj.com", "tsis.jnj.com");

  return hostName === "localhost:4000"
    ? `${protocol}//${hostName}`
    : `${protocol}//${apiHostName}`;
};
