import React, { useEffect, useState } from 'react';
import './buddy.css';
import TableauReport from 'tableau-react';
import { getTableauUrl, getRegisterLink } from '../../components/data';
import { getRequest } from '../../services/axiosServices';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import InfoMessage from '../../components/InfoMessage';
import LoadingAnimation from '../../components/loadingAnimation/LoadingAnimation';

const Buddy = ({wwid}) => {
    const [buddyInfo, setBuddyInfo] = useState({type:'add', id:0, label:'Register as a Buddy'})
    const [apiStatus, setApiStatus] = useState({status:false, message:'', error:false});
    const [loading, setLoading] = useState(false)
    // Styling options for TableauReport
    const options = {
        margin: "auto",
        hideTabs: true,
        border: "1px solid black"
    };
    const { hostname } = window.location;
    const subdomain = hostname.split('.')[0];
    useEffect(()=>{
        const getBuddyRegister = async ()=>{
            setLoading(true);
            try {
                const res = await getRequest(getBaseTspmCoreURL() + '/api/datasrcs/1/schemas/prcrmt/tables/buddy_register/records?wwid='+ wwid);
                if(res.data.rowCount > 0){
                    setBuddyInfo({id:res.data?.data[0]?.id, type:'edit', label:'Edit Buddy Registration'})
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                const getErrorMsg = err => err.request?.statusText ? ` ~ ${err.request?.statusText}!` : '!';
                setApiStatus({status:true, message:'Error with getting buddy information, please refresh page or try again later' + getErrorMsg(error), isError:true});
            }
        }
        getBuddyRegister()
    },[wwid])

    return (
        <div className='buddy-container'>
            <h3>Business Services Procurement New Hire Buddy Program</h3>
            <p>Welcome to the Business Services Procurement New Hire Buddy Program page. Here you can see individuals who have registered as a buddy, 
                new hires that have a buddy assigned, can register yourself as a buddy, and access the 
                {' '}<a className='buddy-toolkit-link' href='https://jnj.sharepoint.com/:w:/t/CredoTeam389/Ed1q4hp5ZQtNrWEfSdBrb1YBf1wMB_fYSoBFdui9m7SPnQ?e=P7ldiJ&CID=5EB7BD4C-F8F0-40BB-B0EE-2DAA71C813C3&wdLOR=c11754E8E-A78B-4820-820F-2EBAAA7AC411' target='_blank' rel="noreferrer">Toolkit</a>  
                {' '}that provides essential onboarding tools and materials.</p>
            <section>
                <InfoMessage message={apiStatus.message} isError={apiStatus.isError} onClose={() => setApiStatus({status:false, message:'', isError:false})} status={apiStatus.status} timer={false} showClose={true}/>
            </section>
            {<button className='btns'>{loading ? <LoadingAnimation type={true}/> : <a href={getRegisterLink(subdomain, buddyInfo)}  target="_blank" rel="noreferrer">{buddyInfo.label}</a>}</button>}
            <br/>
            <div>
                <TableauReport
                    url={getTableauUrl(subdomain)}
                    options={options}
                />
            </div>
        </div>
    );
}

export default Buddy;
