import React, { useEffect, useState, useCallback } from 'react';
import { backendURL } from '../../config/constants';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import "./Allocations.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useStickyHeader from "./useStickyHeader.js";
import { deleteRequest, getRequest, postRequest, putRequest } from '../../services/axiosServices';

//Providing month as per month value coming from API
const MONTH_MAP = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

// Allocations component to display grid monthwise for 12 months
// property will be wwwid which is user wwid
const getDetailErrorMsg = err => err.request?.statusText ? ` ~ ${err.request?.statusText}!` : '!';

const Allocations = ({ wwid, username }) => {
    const [columnsData, setColumnsData] = useState([]);
    const [columnMoYr, setColumnMoYr] = useState({});
    const [projects, setProjects] = useState([]);
    const [allocationPct, setAllocationPct] = useState({});
    const [totalValue, setTotalValue] = useState({});
    const [updatedRecord, setUpdatedRecord] = useState(false);
    const [npTotal, setNpTotal] = useState({});
    const [npAllocationPct, setNpAllocationPct] = useState({});
    const [commentText, setCommentText] = useState();
    const [commentData, setCommentData] = useState([]);
    const { tableRef, isSticky } = useStickyHeader();

    
    //Create columns as per allocation data
    const createColumns = useCallback((allocationsRes, type) => {
        if (allocationsRes.length > 0) {
            let projectsData = [], tableData = {}, totals = {};
            allocationsRes.forEach(allocation => {
                const completeDate = `${MONTH_MAP[allocation.mo]} ${allocation.yr}`
                const projectName = type === "allocations" ? allocation.project_name : 'Non Projects'
                if (totals[completeDate]) {
                    totals[completeDate] += allocation ? allocation.allocation_pct : 0
                } else {
                    totals[completeDate] = allocation ? allocation.allocation_pct : 0
                }
                if (projectsData.indexOf(projectName) === -1) {
                    projectsData.push(projectName)
                }
                if (tableData[projectName]) {
                    tableData[projectName][completeDate] = allocation
                } else {
                    tableData[projectName] = {}
                    tableData[projectName][completeDate] = allocation
                }
            })
            if (type === "allocations") {
                setProjects(projectsData);
                setAllocationPct(tableData);
                setTotalValue(totals)
            } else {
                setNpAllocationPct(tableData)
                setNpTotal(totals)
            }
        }
    }, []);

    // get coulumnsByDate for next 11 months and previous month
    const columsByDate = useCallback(() => {
        const currentDate = new Date()
        const upComingMonths = 12;
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        const monthsArr = []
        const monthsObj = {}
        if (currentMonth - 1 === 0) {
            monthsArr.push(`${MONTH_MAP[upComingMonths]} ${currentYear - 1}`);
        } else {
            monthsArr.push(`${MONTH_MAP[currentMonth - 1]} ${currentYear}`);
        }
        for (let i = currentMonth - 1; i < currentMonth + upComingMonths; i++) {
            let displayValue, monthYear;
            //If the current month is Jan, we need to create previous month
            //which is Dec of the previous year
            //This condition is only valid for one scenario when the current month
            //is Jan and will never be entered otherwise
            if (i < 1) {
                displayValue = `Dec ${currentYear - 1}`;
                monthYear = {
                    mo: 12,
                    yr: currentYear - 1
                }
            }
            //If i moves into the next year, handle by adding one to currentYear and
            //subtracting upComingMonths (12) from i to get the month number
            else if (i > upComingMonths) {
                displayValue = `${MONTH_MAP[i - upComingMonths]} ${currentYear + 1}`;
                monthYear = {
                    mo: i - upComingMonths,
                    yr: currentYear + 1
                }
            } 
            //Else i is in the current year, use i and currentYear
            else {
                displayValue = `${MONTH_MAP[i]} ${currentYear}`;
                monthYear = {
                    mo: i,
                    yr: currentYear
                }
            }
            //Check that the displayValue does not exist in the monthsArr and push if so
            //It will exist for the first month, this prevents two entries of the first month in the array
            if (!monthsArr.includes(displayValue)) {
                monthsArr.push(displayValue);
            }
            monthsObj[displayValue] = monthYear
        }
        setColumnsData(monthsArr)
        setColumnMoYr(monthsObj)
    }, []);

    // Get allocations date from rest api and build grid
    const getAllocations = useCallback(async () => {
        const allocationsUrl = `${getBaseTspmCoreURL()}${backendURL().allocationsPath}`;
            try {
                const res  =  await getRequest(`${allocationsUrl}?filters=${wwid}`)
                const newData = res.data.data?.sort((a, b) => a.request_id - b.request_id);
                createColumns(newData, 'allocations')
                columsByDate()
            } catch (error) {
                toast.error(`Error allocations: ${getDetailErrorMsg(error)}`);
            }
    }, [columsByDate, createColumns, wwid]);

    // Get Non Project allocations date from rest api and build grid
    const getNonProjectAllocations = useCallback(async () => {
        const NonProAllocationsUrl = `${getBaseTspmCoreURL()}${backendURL().nonProjectAllocationsPath}`;
        try {
            const jsonData = await getRequest(`${NonProAllocationsUrl}?resource_wwid=${wwid}`);
            createColumns(jsonData.data?.data, 'np');
        } catch (error) {
            toast.error(`Error fetching non-project allocations: ${getDetailErrorMsg(error)}`);
        }
    }, [createColumns, wwid]);
    
    // Get comment data from rest api andshow
    const getAllocationsComment = useCallback(async () => {
        const allocationsCommentUrl = `${getBaseTspmCoreURL()}${backendURL().allocationsComment}`;
        try {
            const jsonData = await getRequest(`${allocationsCommentUrl}?wwid=${wwid}`);
            setCommentData(jsonData.data.data);
            setCommentText(jsonData.data.data?.[0]?.comment);
        } catch (error) {
            toast.error(`Error fetching allocations comment: ${getDetailErrorMsg(error)}`);
        }
    }, [wwid]);
   
    useEffect(() => {
        
        getAllocations();
        getNonProjectAllocations();
        getAllocationsComment();
        
    }, [updatedRecord, wwid, getAllocations, getNonProjectAllocations, getAllocationsComment])

    // Update value of grid column PUT,DELETE, POST
    const updateValue = async (e, allocation) => {

        if (e.target.value !== "" && allocation?.allocation_pct === +e.target.value) {
            return;
        }
        const updateAllocationsUrl = `${getBaseTspmCoreURL()}${backendURL().updateAllocations}`;
    try {
        //Send Delete request if user delete allocations
        if (e.target.value === "") {
            await deleteRequest(`${updateAllocationsUrl}/${allocation.allocation_id}`);
        //Send post request if user updated allocations value
        } else if (!allocation.allocation_id) {
            await postRequest(updateAllocationsUrl, {
                request_id: +allocation.request_id,
                yr: +allocation.yr,
                mo: +allocation.mo,
                allocation_pct: +e.target.value
            });
            //Send put request if user updated allocations value
        } else {
            await putRequest(`${updateAllocationsUrl}/${allocation.allocation_id}`, {
                allocation_pct: e.target.value
            });
        }
        setUpdatedRecord(prev => !prev);
    } catch (error) {
        toast.error(`Error updating value: ${getDetailErrorMsg(error)}`);
    }}

    // Update non project value of grid column PUT,DELETE, POST
    const updateNonProjects = async (e, allocation) => {
        if (e.target.value !== "" && allocation?.allocation_pct === +e.target.value) {
            return;
        }
        const updateAllocationsUrl = `${getBaseTspmCoreURL()}${backendURL().nonProjectAllocationsPath}`;
        try {
            if (!allocation.id) {
                //Send post request if user updated non project allocations value
                await postRequest(updateAllocationsUrl, {
                    resource_wwid: wwid,
                    yr: +allocation.yr,
                    mo: +allocation.mo,
                    allocation_pct: +e.target.value
                });
            } else if (e.target.value === "") {
                //Send put request if user updated non project allocations value
                await putRequest(`${updateAllocationsUrl}/${allocation.id}`, { allocation_pct: +0 });
            } else {
                //Send put request if user updated non project allocations value
                await putRequest(`${updateAllocationsUrl}/${allocation.id}`, {
                    allocation_pct: e.target.value
                });
            }
            setUpdatedRecord(prev => !prev);
        } catch (error) {
            toast.error(`Error updating non-projects: ${getDetailErrorMsg(error)}`);
        }
    }
    //function for Post comment to add in projects data
    const postComment = async () => {
        const postCommentUrl = `${getBaseTspmCoreURL()}${backendURL().allocationsComment}`;
        try {
            if (commentData.length === 0) {
            //Post new comment for projects data
            // First time user can add their comment
                await postRequest(postCommentUrl, {
                    wwid: wwid,
                    comment: commentText
                });
                toast.success("Comment has been saved successfully");
            //Put comment for projects data
            // if there is allready comment then update existing comment
            } else {
                await putRequest(`${postCommentUrl}/${wwid}`, {
                    comment: commentText
                });
                toast.success("Comment has been updated successfully");
            }
            setUpdatedRecord(prev => !prev);
        } catch (err) {
            toast.error(`Error while saving your comment: ${getDetailErrorMsg(err)}`);
        }
    }

    // Build allocation with month and year
    const buildAllocation = (data, displayDate) => {
        if (data) return data;
        const monthyr = columnMoYr[displayDate]
        return {
            yr: monthyr?.yr,
            mo: monthyr?.mo
        }
    }

    // This function return header of table
    const renderHeader = () => {
        return (
            <thead>
                <tr key={'tesra'}>
                    <th scope="col" style={{width:'100px', textAlign: 'center'}}>Id</th>
                    <th scope="col" style={{textAlign: 'center'}}>Requests</th>
                    <th scope="col" style={{width:'200px', textAlign: 'center'}}>Status</th>

                    {columnsData.map((data, index) =>
                        <th scope="col" key={index} className="all-month">{data}</th>
                    )}
                </tr>
            </thead>
        );
    }

    return (
        <div className=''>
            {/* Welcome message for user  */}
            {/* Username imported from App.js file */}
            <div className='allocation-welcomeMsg'>
                <p><strong>Welcome {username},</strong> please enter the percentage of time allocated to each project for a given month.</p>
                <p> In the non-project section enter the percentage of time you plan to spent on administrative and non project related work.</p>
            </div>
            <div className='table-wrapper table-responsive'>
                {/* Sticky table header */}
                {isSticky && (
                    <table className="table table-bordered sticky">
                        {renderHeader()}                        
                    </table>
                )}
                <table className="table table-bordered" ref={tableRef}>
                    {renderHeader()}
                    <tbody>
                        {projects.map((projectName, index) => {
                            const requestId = Object.values(allocationPct[projectName])?.[0]?.request_id
                            return <tr key={index}>
                                <td  colSpan="1">{requestId}</td>
                                <td className="all-Projects">{projectName}</td>
                                <td  colSpan="1">{allocationPct[projectName][columnsData[0]]?.project_status}</td>
                                {columnsData.map((coldate, i) => {
                                    const allocation = allocationPct[projectName][coldate]
                                    return <td key={i}>
                                        {allocation && <input type="number" min="0" max="100" defaultValue={allocation?.allocation_pct} onBlur={e => updateValue(e, allocation)} data-testid='allocation'/>}</td>
                                })}
                            </tr>
                        })}
                        <tr className="all-total-row">
                            <th scope='row' colSpan="2">Request Allocation Total</th>
                            <th scope='row' colSpan="1"></th>
                            {columnsData.map((colDate, i) => {
                                return <td key={i}>
                                    {totalValue[colDate]}
                                </td>
                            })}
                        </tr>
                        {/* Start of Non project allocations row */}
                        <tr>
                            <th scope="row" className="all-Projects" colSpan="2">Non Project</th>
                            <th scope='row' colSpan="1"></th>
                            {columnsData.map((coldate, i) => {
                                const allocation = buildAllocation(npAllocationPct['Non Projects']?.[coldate], coldate)
                                return <td key={i}>
                                    <input type="number" min="0" max="100" defaultValue={allocation?.allocation_pct} onBlur={e => updateNonProjects(e, allocation)} data-testid='allocation'/>
                                </td>
                            })}
                        </tr>
                        <tr className="all-total-row">
                            <th scope='row' colSpan="2">Grand Total</th>
                            <th scope='row' colSpan="1"></th>
                            {columnsData.map((colDate, i) => {
                                return <td key={i}>
                                    {(npTotal[colDate] ?? 0) + (totalValue[colDate] ?? 0)}
                                </td>
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* Footer section after table */}
            {/* comment box for text message*/}
            {/* buttons for send request */}
            <div className='card-footer py-3 border-0'>
                <div className="form-outline  w-50 mb-4 allocation-commentBox">
                    <label className="form-label" htmlFor="textArea">Please provide the details of "Non-Project" work in this box</label>
                    <textarea role="textarea" className="form-control" id="textArea" rows="3" data-testid="comment" onChange={e => setCommentText(e.target.value)} value={commentText || ''}></textarea>
                    <div className='btn-wrapper mt-2 pt-1'>
                        <button type="button" className="btn btn-primary btn-sm" onClick={postComment} data-testid="saveComment">
                            Save comment
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    );
};

export default Allocations;