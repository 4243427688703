import React from 'react';
import { useParams } from 'react-router-dom';
import { getTableauId } from '../../components/data';
import TableauReport from 'tableau-react';

//Importing get tableau ID function to apply to tables in data.js
//Params are used in App.js
const Dashboard = () => {
    let params = useParams();
    let tableau = getTableauId(params.id);
    
//Styling options
    const options = {
        margin: "auto",
        hideTabs: tableau.hideTabs,
        border: "1px solid black"
    }

    return (
        <TableauReport
            url={tableau.url}
            options={options}
        />
    );
};

export default Dashboard;