import { AlertTitle, Alert } from '@mui/material';
import React, { Fragment } from 'react';
import { getBaseTspmCoreURL } from '../utils/getBaseUrls';
import PropTypes from 'prop-types';
const baseUrl = getBaseTspmCoreURL()
//used for specifically authentication
//should render and redirect instantly
const AuthRedirectError = props => {
    let redirectURL = window?.location || '';
  
    const ERROR_CODE = 401;
    const url = baseUrl + `/api/login?redirectURL=${redirectURL}`;

    if (props.error === ERROR_CODE) {
        window.location.replace(url);
    }

    return (
        <Fragment>
            {props.error === ERROR_CODE ? (
                <Alert severity="info">
          Its trying to Sign in, please wait a moment!
                </Alert>
            ) : (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
          Something went wrong <strong>Please try again!</strong>
                </Alert>
            )}
        </Fragment>
    );
};

//prop types
AuthRedirectError.propTypes = {
    error:PropTypes.object
};
//export
export default AuthRedirectError;