import { getBaseTspmCoreURL } from "../utils/getBaseUrls";
import { useEffect, useState } from "react";
import { getRequest } from "../services/axiosServices";
import useMainStore from '../store/useMainStore';


export default function useAuthentication() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [status, setStatus] = useState(null);
    const [isError, setIsError] = useState(false);
    const {setIsAuthorized} = useMainStore();
  
    //contains array for access control list
    const [userData, setUserData] = useState(null);
    const [wwidData, setWwidData] = useState(null);
  //authentication of me call state
  //makes api call to retrieve user data. Call executes any time the application is rendered
  //into a new component
  const checkAuth = async ()=>{
    try {
        const res = await getRequest(`${getBaseTspmCoreURL()}/api/me/false`);
        setStatus(res.request.status);
        setUserData(res.data.user.email);
        setWwidData(res.data.user.wwid);
        setIsAuthenticated(true);
        setIsAuthorized(true)
        setIsError(false);
    } catch (error) {
        //sets status to error, which triggers auth error component
        setStatus(error?.request?.status);
        setIsError(true);
    }
  };

  useEffect(() => {
      checkAuth();
  }, []);

  return {userData,wwidData,status,isAuthenticated,isError}
        
}
