import React, { useRef } from "react";
import logo from "../img/logo.png";
import { tableauLinks } from "./data";
import { MdQuiz } from "react-icons/md";
import { MdContactMail } from "react-icons/md";
import { Link } from "react-router-dom";
import { ControlledMenu, MenuItem, useHover, useMenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import './navbar.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { getBaseTspmCoreURL } from "../utils/getBaseUrls";


const NavbarTop = props => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);


  return (
    <nav className="navbar">
      <div className="navbar-section logo">
      <a href={`${getBaseTspmCoreURL()}`} target="_blank" rel="noreferrer">
        <img
          src={logo}
          width="155"
          height="60"
          className="logo-image"
          alt="Team logo"
        />
      </a>
      </div>
      <h5 className="navbar-brand header-logoText" style={{ fontSize: "24px" }}>
        NA BU IT Procurement
      </h5>
      <div className="navbar-section menu">
        <span><Link to="/">Home</Link></span>
        <div className="react-menu-container">
          <span ref={ref} {...anchorProps}>
            Dashboards
          </span>
          <ControlledMenu
            {...hoverProps}
            {...menuState}
            anchorRef={ref}
            onClose={() => toggle(false)}
          >
            {tableauLinks.map(item => {
              const link = "/dashboard/" + item.id;
              return (
                <MenuItem
                  key={item.id}
                >
                  <Link to={link}>{item.name}</Link>
                </MenuItem>
              );
            })}
          </ControlledMenu>
        </div>
        <span><Link to="/allocations">Allocations</Link></span>
        <span><Link to="/buddy">Buddy</Link></span>
      </div>
      <div className="navbar-section user">
        <MdQuiz  style={{ marginRight: "10px", fontSize:'24px'}} />
        <a
          href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNDI2NDc2NyJ9/all"
          target="_blank"
          rel="noreferrer"
          style={{ color: "grey", marginRight: "10px" }}
        >
          <MdContactMail size='24px'/>
        </a>
        <div>
          {props.username}
        </div>
      </div>
    </nav>
  );
};

export default NavbarTop;
