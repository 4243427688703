import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarTop from "./components/NavbarTop";
import {
  Route,
  Routes, 
  useLocation,
} from "react-router-dom";
import { Home } from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import Allocations from "./pages/allocations/Allocations";
import Loading from "./components/loading";
import AuthRedirectError from "./components/AuthRedirectError";
import useAuthentication from "./components/useAuthentication";
import LogPageView from "./components/LogPageView";
import AuthReload from "./components/AuthReload";
import Buddy from "./pages/buddy/Buddy";
/* eslint-disable react-hooks/exhaustive-deps */

function App() {
  //runs authentication
  //Connect to get user information
  const { userData, wwidData, status, isAuthenticated, isError } = useAuthentication();
  const [path, setPath] = useState("");

  const location = useLocation();
    useEffect(() => {
        setPath(location.pathname);
    }, [location.pathname]);

  //authenticate and log user page view
  useEffect(() => {
    if (isAuthenticated) {
      LogPageView(userData);
    }
  }, [path, isAuthenticated]);
  return (
    <div className="App">
      {isError && <AuthRedirectError error={status} />}
      {isAuthenticated ? (
        <>
          {/* Add dynamic routing for dashboards */}
            <AuthReload />
            <NavbarTop username={userData?.split("@")[0]} />
            <Routes>
              <Route path="/" element={<Home />}></Route>
              {/* Using :id to create link for each dashboard in data.js  */}
              <Route path="dashboard/:id" element={<Dashboard />}></Route>
              <Route path="/allocations/" element={<Allocations wwid={wwidData} username={userData?.split("@")[0]}/>}></Route>
              <Route path="buddy" element={<Buddy wwid={wwidData}/>}></Route>
            </Routes>
        </>
      ) : (
        <Loading isLoading={true} />
      )}
    </div>
  );
}

export default App;
