import dateFormat from "date-and-time";
import { getBaseTspmCoreURL } from "../utils/getBaseUrls";
import { postRequest } from "../services/axiosServices";
const baseUrl = getBaseTspmCoreURL();

//logs when the user visits a page
export default function LogPageView(userData) {
  
    const startTimeFormatted = dateFormat.format(
      new Date(),
      "YYYY-MM-DD HH:mm:ss.SSS"
    );
    const { pathname } = window.location; 
    
    //only sends call if user data is loaded
    if (userData) {
        //this is the object sent to the backend that contains log data.
        const logData = {
          user_name: userData?.split("@")[0],
          pathname: pathname,
          hostname: window.location.hostname,
          visit_timestamp: startTimeFormatted,
        };

        const sendLoggedUrl =async ()=>{
          try {
            await postRequest(baseUrl + '/api/datasrcs/1/schemas/tspmcfg/tables/page_view_log/records', logData);
          } catch (error) {
            console.log(error)
          }
            
        };
        sendLoggedUrl();
    }
}
