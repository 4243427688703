import React from "react";
import "../card-style.css";
import Cards from "./Cards";
import { createURL } from "../config/constants.js"
import { Link } from "react-router-dom";
import { MdOutlineAddchart } from "react-icons/md";
import { GiGears } from "react-icons/gi";
import { FcTimeline } from "react-icons/fc";
import { FcDoughnutChart } from "react-icons/fc";
import { FcStatistics } from "react-icons/fc";
import { FcNumericalSorting12 } from "react-icons/fc";
import { IoPeopleCircle } from "react-icons/io5";

const cardsData = [
  {
    title: "Create Request",
    imgsrc: <MdOutlineAddchart size='152px'/>,
    alt: "Create",
    link: createURL(),
    isExternal: true,
  },
  {
    title: "Executive Dashboard",
    imgsrc: <FcStatistics size='152px'/>,
    alt: "Executive",
    link: "/dashboard/executive",
  },
  {
    title: "Operational Dashboard",
    imgsrc: <GiGears size='152px'/>,
    alt: "Operational",
    link: "/dashboard/operational",
  },
 
  {
    title: "Priority Dashboard", 
    imgsrc: <FcNumericalSorting12 size='152px'/>,
    alt: "Priority Dashboard",
    link: "/dashboard/priority",
  },
  {
    title: "Priority by Category", 
    imgsrc: <FcTimeline size='152px'/>,
    alt: "Priority by Category",
    link: "/dashboard/priorityCategory",
  },
  {
    title: "Allocations",
    imgsrc: <FcDoughnutChart size='152px'/>,
    alt: "Allocations",
    link: "/allocations",
  },
  {
    title: "Buddy Program",
    imgsrc: <IoPeopleCircle size='152px'/>,
    alt: "Buddy Program",
    link: "/buddy",
  },
];

export default function CardsDeck() {
  return (
    <>
      <div className="row row-cols-1 row-cols-md-4 g-4">
        {cardsData.map((card, index) => (
          <div className="col" key={index}>
            <div className="card">
              {card.isExternal ? (
                <a href={card.link} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <Cards title={card.title} imgsrc={card.imgsrc} alt={card.alt} />
                </a>
              ) : (
                <Link to={card.link} style={{ textDecoration: "none" }}>
                  <Cards title={card.title} imgsrc={card.imgsrc} alt={card.alt} color={card.color}/>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
