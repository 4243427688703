//This file creates a prop for CardsComp
//Edit the Card Template in this file
//CardsDeck is applied to Home.jsx
//Styling is in card-style.css
import React from "react";
import "../card-style.css";

const Cards = props =>{
    
    return (
      //Add centered text card to use on Home.jsx
      // className="card text-center
        <div>
            <div 
            style={{
              height: '224px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: props.color || '#3f51b5'
            }}
            >
              {props.imgsrc}
            </div>
          <div className="card-body text-dark text-center">
            <h5 className="card-title">{props.title}</h5>
          </div>

        </div>

)
    
} 
//Export Card
export default Cards;