import CardsDeck from "../../components/CardsDeck"
import React from "react";
//Home Page body - Adding the 3 cards to main screen
//To update card format go to Cards.jsx
export function Home () {
    return (
        <div className="container">
            <CardsDeck />
        </div>
    )
}
