//Set up URL for all envrionments based on prefix
//Takes the window location and adds prefix based on environment
export const createURL = () => {
  if (
    window.location.hostname.startsWith("predev") ||
    window.location.hostname === "localhost"
  ) {
    return "https://predev.tsis.jnj.com/data_maintenance/40/form/add/0";
  } else if (window.location.hostname.startsWith("dev")) {
    return "https://dev.tsis.jnj.com/data_maintenance/17/form/add/0";
  } else if (window.location.hostname.startsWith("qa")) {
    return "https://qa.tsis.jnj.com/data_maintenance/321/form/add/0";
  } else  {
    return "https://tsis.jnj.com/data_maintenance/64/form/add/0";
  }
};

//Used for authentication and log page views
//location of page views
export const backendURL = () => {
  return {
    detailsTableData: "/api/datasrcs/1/schemas/",
    allocationsPath:'/api/datasrcs/1/folder/prcrmt/customQuery/request_allocation_by_assignee',
    updateAllocations:'/api/datasrcs/1/schemas/prcrmt/tables/request_allocation/records',
    nonProjectAllocationsPath:'/api/datasrcs/1/schemas/prcrmt/tables/non_project_allocation/records',
    allocationsComment:'/api/datasrcs/1/schemas/prcrmt/tables/non_project_allocation_comment/records',
  };
};
//Adding https status codes
//Console log status to return code
export const httpStatuses = {
  OK: 200,
  CREATED: 201,
  MULTIPLE_CHOICES: 300,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};
