//Dashboard page for tableau links
//If dashboard source changes update URL below
//This is pulled into Dashboard.jsx component
//Leave filter as null
//This was copied from https://sourcecode.jnj.com/projects/ASX-JAUJ/repos/rmr/browse/frontend/src/components/data.js
// Links name first character should be uppercase
//Added tableau links 
//Added Executive and Operational tabs
export const tableauLinks = [
  {
    id: "executive",
    name: "Executive",
    url: "https://prodbitabcrp.jnj.com/t/IT-Insights/views/ProcurementDashboards/ExecutiveDashboard&:jsdebug=n",
    hideTabs: true,
    filter: null,
  },
  {
    id: "operational",
    name: "Operational",
    url: "https://prodbitabcrp.jnj.com/t/IT-Insights/views/ProcurementDashboards/DemandDashboard&:jsdebug=n",
    hideTabs: true,
    filter: null,
  },
  {
    id: "priority",
    name: "Priority",
    url: "https://prodbitabcrp.jnj.com/t/IT-Insights/views/ProcurementDashboards/Top5PriorityDashboard&:jsdebug=n",
    hideTabs: true,
    filter: null,
  },
  {
    id: "priorityCategory",
    name: "Priority by Category",
    url: "https://prodbitabcrp.jnj.com/t/IT-Insights/views/ProcurementDashboards/PriorityScoreDetailbyCategory&:jsdebug=n",
    hideTabs: true,
    filter: null,
  }, 
];
//Get tableau dashboard
// Function for finding tableau link
//For each item, return dashboard
export function getTableauId(idTableau) {
  return tableauLinks.find(item => item.id === idTableau);
}

// function to determine the URL based on the hostname
export const getTableauUrl = subdomain => {
  
  const allowedHostnames = ['predev', 'dev', 'qa', 'localhost'];
 
  if (allowedHostnames.includes(subdomain)) {
      return 'https://qabitabcrp.jnj.com/t/IT-Insights/views/ProcurementBuddyDashboard/BuddyRegisterDashboard&:jsdebug=n';
  }

  return 'https://prodbitabcrp.jnj.com/t/IT-Insights/views/ProcurementBuddyDashboard/BuddyRegisterDashboard&:jsdebug=n';
};

// function to determine the URL based on the hostname
export const getRegisterLink = (subdomain, buddyInfo)=> {
 
  if (subdomain === 'predev' || subdomain === 'localhost') {
      return `https://predev.tsis.jnj.com/data_maintenance/4246/form/${buddyInfo.type}/${buddyInfo.id}`;
  }
  if (subdomain === 'dev') {
    return `https://dev.tsis.jnj.com/data_maintenance/146/form/${buddyInfo.type}/${buddyInfo.id}`;
  }
  if (subdomain === 'qa') {
    return `https://qa.tsis.jnj.com/data_maintenance/509/form/${buddyInfo.type}/${buddyInfo.id}`;
  }
  if (subdomain === 'itprocurement') {
    return `https://tsis.jnj.com/data_maintenance/161/form/${buddyInfo.type}/${buddyInfo.id}`;
  }

  return 'https://tsis.jnj.com';
};